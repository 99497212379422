<template>
    <ul class="the-booking-overlay__form-list">
        <li
            v-for="(person, index) in modelValue"
            :key="index"
        >
            <label
                class="the-booking-overlay__label"
                :for="`${index}`"
            >
                <span>{{ $t(index) }}</span>
                <span class="the-booking-overlay__label-key">
                    {{ $t(`${index}-description`) }}
                </span>
            </label>
            <!-- eslint-disable vue/no-mutating-props-->
            <BaseCounter
                :id="index"
                v-model="modelValue[index]"
                :name="index"
                :min="0"
                :max="maxOccupancy(index)"
            />
            <!-- eslint-enable vue/no-mutating-props-->
        </li>
    </ul>
</template>

<script setup>
const props = defineProps({
    formData: {
        type: Object,
        required: true
    },
    modelValue: {
        type: Object,
        required: true
    }
});

const maxOccupancy = (type) => {
    const maximum = 4;
    const maxChildren = 2;
    if (type === 'adults') {
        return maximum - props.modelValue.kids - (props.modelValue.infants || 0);
    } else if (type === 'kids') {
        if (props.modelValue.kids === maxChildren) {
            return maxChildren;
        }
        return maximum - props.modelValue.adults - (props.modelValue.infants || 0);
    } else if (type === 'infants') {
        if (props.modelValue.infants === maxChildren) {
            return maxChildren;
        }
        return maximum - props.modelValue.adults - props.modelValue.kids;
    }
};
</script>
