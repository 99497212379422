<script lang="js" setup>
const props = defineProps({
    formData: {
        type: Object,
        required: true
    },
    mewsId: {
        type: String,
        required: true
    },
    location: {
        type: Object,
        default: () => {},
        required: false
    },
    roomId: {
        type: String,
        default: '',
        required: false
    },
});

onMounted(() => {
    if (!props.formData.date) {
        return;
    }

    const startDate = new Date(props.formData.date.start);
    const [ startYear, startMonth, startDay ] = new Date(startDate).toISOString().split('T')[0].split('-');
    const [ endYear, endMonth, endDay ] = new Date(props.formData.date.end).toISOString().split('T')[0].split('-');

    useHead({
        script: [
            {
                src: 'https://api.mews.com/distributor/distributor.min.js'
            },
            {
                innerHTML: `Mews.Distributor({
                    configurationIds: [
                        '${ props.mewsId }'
                    ],
                },
                function(api) {
                    ${ props.formData?.date?.start ? `api.setStartDate(new Date(${startYear}, ${ Number(startMonth) - 1}, ${ Number(startDay) + 1}));` : ''}

                    ${ props.formData?.date?.end ? `api.setEndDate(new Date(${endYear}, ${ Number(endMonth) - 1}, ${ Number(endDay) + 1}));` : ''}

                    ${ props.formData?.guests?.adults ? `api.setAdultCount(${props.formData?.guests?.adults});` : '' }
                    ${ props.formData?.guests?.kids ? `api.setChildCount(${props.formData?.guests?.kids});` : '' }

                    ${ props.formData?.code ? `api.setVoucherCode('${props.formData?.code}');` : '' }

                    ${ props.roomId !== '' ? `api.showRates('${props.roomId}');` : 'api.showRooms();'}

                    api.open();
                });`
            }
        ]}
    );
});
</script>
