<template>
    <BaseDropdown
        key="overlay"
        class="the-booking-overlay"
        :custom-trigger="overlayOpen"
        data-direction="down"
        data-direction-sm="up"
        @close="handleClose()"
    >
        <template #title>
            <menu class="the-booking-overlay__menu">
                <BaseButton
                    v-for="step in [1, 2, 3]"
                    :key="step"
                    class="the-booking-overlay__menu-button"
                    :active="activeStep === step"
                    :disabled="!allowedSteps.includes(step)"
                    @click="allowedSteps.includes(step) ? handleClickStep(step) : null"
                >
                    {{ getStepTitle(step) }}
                </BaseButton>
            </menu>
        </template>

        <template #body>
            <transition
                :name="animationDirection === 'forwards' ? 'booking-overlay-step' : 'booking-overlay-step--backwards'"
                mode="out-in"
            >
                <TheBookingOverlayStep
                    v-if="activeStep === 1"
                    :active-step="activeStep"
                    :step="1"
                    :can-continue="allowedSteps.includes(2)"
                    @update:step="handleClickStep"
                >
                    <template #default>
                        <ThumbnailList
                            :items="properties"
                            :active-item="formData.location"
                            :pending="pending"
                            @click:item="handleClickHotel"
                        >
                            <template
                                v-for="(property, index) in properties"
                                :key="`property-${index}`"
                                #[`item-${index}`]
                            >
                                <BaseDotLink
                                    :active="formData.location === property"
                                >
                                    <DashedText>
                                        <template #left>
                                            {{ property.city?.[0]?.title }}
                                        </template>
                                        <template #right>
                                            <template v-if="property.propertyPhase === 'comingSoon'">
                                                {{ $t('coming-soon') }}
                                            </template>
                                            <template v-else>
                                                {{ property.area }}
                                            </template>
                                        </template>
                                    </DashedText>
                                </BaseDotLink>
                            </template>
                        </ThumbnailList>
                    </template>

                    <template #button>
                        {{ $t('booking-overlay.step-1.prompt') }}
                    </template>
                </TheBookingOverlayStep>

                <TheBookingOverlayStep
                    v-else-if="activeStep === 2"
                    :active-step="activeStep"
                    :step="2"
                    @update:step="handleClickStep"
                >
                    <template #default>
                        <BaseDatePicker
                            v-model="formData.date"
                            :active-property="formData.location"
                        />
                    </template>

                    <template #button>
                        {{ $t('booking-overlay.step-2.prompt') }}
                    </template>
                </TheBookingOverlayStep>

                <TheBookingOverlayStep
                    v-else-if="activeStep === 3"
                    v-model:guests="formData.guests"
                    :active-step="activeStep"
                    :step="3"
                    @finish="handleFinish"
                >
                    <template #default>
                        <BasePersonFilter 
                            v-model="formData.guests"
                        />
                    </template>

                    <template #button>
                        {{ $t('booking-overlay.step-3.prompt') }}
                    </template>
                </TheBookingOverlayStep>
            </transition>
            <MewsBookingEngine
                v-if="bookerActive"
                :form-data="formData"
                :mews-id="selectedLocation?.mewsId"
            />
        </template>
    </BaseDropdown>
</template>

<script setup>
import PropertiesQuery from '~/graphql/queries/properties.graphql';
import { useGtm } from '@gtm-support/vue-gtm';

// Always load the Mews Distributor script
useHead({
    script: [
        {
            src: 'https://api.mews.com/distributor/distributor.min.js'
        },
    ]
});

const { variables } = useCraftGraphql();

// Ignore the URI in this case
delete variables.uri;

const { pending, data } = await useLazyAsyncQuery({
    query: PropertiesQuery,
    variables
});

const properties = computed(() => {
    return data?.value?.entries.filter((property) => {
        return property.propertyPhase !== 'inDevelopment';
    });
});

import { format, isSameMonth } from 'date-fns';
import { useI18n } from 'vue-i18n';
import { useBookingOverlayStore } from '@/store/booking-overlay';

const { t } = useI18n();

const bookingOverlayStore = useBookingOverlayStore();
const overlayOpen = computed(() => bookingOverlayStore.isBookingOverlayOpen);
const selectedLocation = ref(null);

const formData = ref({
    location: {},
    date: {
        start: null,
        end: null
    },
    guests: {
        adults: 1,
        kids: 0
    }
});

const previousStep = ref(null);
const activeStep = ref(1);
const animationDirection = ref('forwards');

function setStep(step) {
    previousStep.value = activeStep.value;
    activeStep.value = step;

    if (previousStep.value > activeStep.value) {
        animationDirection.value = 'backwards';
    } else {
        animationDirection.value = 'forwards';
    }
}

function getStepTitle(step) {
    if (step <= activeStep.value) {
        if (step === 1 && toValue(formData).location?.city?.[0]?.title) {
            if (toValue(formData).location?.city?.[0]?.title) {
                const shortCity = toValue(formData).location?.city[0].title.slice(0,3);
                return `${shortCity.toUpperCase()} – ${toValue(formData).location?.area.toUpperCase()}`;
            }
        }

        if (step === 2 && toValue(formData).date.start && toValue(formData).date.end) {
            let start = format(toValue(formData).date.start, 'dd');
            const end = format(toValue(formData).date.end, 'dd MMM');

            if (!isSameMonth(toValue(formData).date.start, toValue(formData).date.end)) {
                start = format(toValue(formData).date.start, 'dd MMM');
            }

            return `${start} – ${end}`;
        }
    }

    return t(`booking-overlay.step-${step}.title`);
}

function handleClose() {
    bookingOverlayStore.closeBookingOverlay();
}

function handleClickHotel(hotel) {
    if (selectedLocation.value === hotel) {
        selectedLocation.value = null;
        formData.value.location = {};
    } else {
        selectedLocation.value = hotel;
        formData.value.location = hotel;
        handleClickStep(2);
    }
}

function handleClickStep(step) {
    if (!allowedSteps.value.includes(step)) {
        return;
    }

    setStep(step);

    // Track event
    let event = null;

    switch (step) {
    case 2:
        event = 'select_dates_click';
        break;
    case 3:
        event = 'select_guests_click';
        break;
    }

    if (event) {
        const gtm = useGtm();
        gtm.trackEvent({
            /* eslint-disable camelcase */
            event,
            /* eslint-enable camelcase */
        });
    }
}

const bookerActive = ref(false);

function handleFinish() {
    const gtm = useGtm();
    gtm.trackEvent({
        /* eslint-disable camelcase */
        event: 'check_availability_click',
        /* eslint-enable camelcase */
    });

    // if (bookingEngineUrl.value !== null) {
    //     // window.open(bookingEngineUrl.value);
    //     // Below is the original code
    //     router.push('/' + bookingEngineUrl.value);
    // }

    bookerActive.value = false;

    setTimeout(() => {
        bookerActive.value = true;
    }, 50);

    // bookingOverlayStore.closeBookingOverlay();
    // formData.value = { ...defaultFormData };

    // setStep(1);
}

const route = useRoute();

const allowedSteps = computed(() => {
    if (!selectedLocation.value) {
        return [1];
    }

    if (Object.keys(selectedLocation.value).length === 0) {
        return [1];
    }

    if(selectedLocation.value.mewsId === null) {
        return [1];
    }

    return [1,2,3];
});

const { locale } = useI18n();

watch(overlayOpen, (isOpen) => {
    const root = locale.value === 'en' ? '/' : `/${locale.value}/`;

    if (isOpen) {
        if (
            route.path &&
            route.path.includes('/properties/')
        ) {
            const matchedProperty = () => {
                const exactMatch = properties.value?.find((property) => {
                    return root + property.uri === route.path;
                });

                const nestedExactMatch = properties.value?.find((property) => {
                    return route.path.startsWith(root + property.uri + '/');
                });

                const partialMatch = properties.value?.find((property) => {
                    return route.path.startsWith(root + property.uri);
                });

                if (exactMatch) {
                    return exactMatch;
                } else if (nestedExactMatch) {
                    return nestedExactMatch;
                } else if (partialMatch) {
                    return partialMatch;
                }
            };

            if (matchedProperty() && !matchedProperty()?.comingSoon) {
                selectedLocation.value = matchedProperty();
                toValue(formData).location = matchedProperty();
                setStep(2);
            } else {
                selectedLocation.value = {};
                toValue(formData).location = null;
                setStep(1);
            }
        } else {
            selectedLocation.value = {};
            toValue(formData).location = {};
            setStep(1);
        }
    }
});
</script>

<style lang="less">
.the-booking-overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 400;


    .base-dropdown__dropdown {
        // Override dropdown transition to account for homepage banner
        --transition-transform-amount: 110%;

        width: 100%;
    }

    .base-dot-link {
        display: flex;
    }

    @media @q-md-min {
        position: absolute;
        top: 0;
        bottom: auto;
        left: calc((var(--grid-column-width) + var(--grid-gutter-width)));
    }

    @media @q-lg-min {
        left: 50%;
    }

    @media @q-xl-min {
        left: calc((var(--grid-column-width) + var(--grid-gutter-width)) * 2);
    }
}

.the-booking-overlay__menu {
    .list-clean();

    display: flex;
    justify-content: space-between;
    width: calc(100% + .75rem);

    margin-top: -.4rem;
    margin-bottom: var(--spacing-md);
    margin-left: -.75rem;

    position: relative;
    z-index: 1;

    &:after {
        content: '';
        display: block;
        height: 1px;
        background: var(--color-darkest);
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.the-booking-overlay__form-list {
    .list-clean();

    display: flex;
    flex-direction: column;
    gap: .25rem;

    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: var(--spacing-sm);

        padding-block: var(--spacing-xs);
        border-bottom: 1px solid var(--color-cream);
    }
}

.the-booking-overlay__label {
    display: flex;
    flex-direction: column;
    gap: .125rem;
}

.the-booking-overlay__label-key {
    .typography-small();
}

.dn-button.the-booking-overlay__menu-button {
    font-size: .875rem;
    height: 2rem;
    padding-inline: .75rem;
    margin-top: 0;

    white-space: nowrap;

    &:hover {
        background: var(--color-lightest);
    }

    &[active='true'] {
        background: var(--color-oasis-green);
        color: var(--color-lightest);
    }
}

@media ( prefers-reduced-motion: no-preference ) {
    .booking-overlay-step-enter-from .the-booking-overlay-step__body {
        opacity: 0;
        transform: translateX(1.5rem);
    }

    .booking-overlay-step-leave-to .the-booking-overlay-step__body {
        opacity: 0;
        transform: translateX(-1.5rem);
    }

    .booking-overlay-step--backwards-enter-from .the-booking-overlay-step__body {
        opacity: 0;
        transform: translateX(-1.5rem);
    }

    .booking-overlay-step--backwards-leave-to .the-booking-overlay-step__body {
        opacity: 0;
        transform: translateX(1.5rem);
    }

    .the-booking-overlay-step__body {
        transition: transform var(--transition-timing-medium) 0ms var(--transition-timingFunction-in-out), opacity var(--transition-timing-medium) 0ms var(--transition-timingFunction-in-out);
    }
}
</style>
