<template>
    <article
        class="home"
        :data-show-loading-state="!loadingStateShown"
    >
        <LazyTheHomeBanner
            v-if="entry?.banner?.[0]?.text"
            :banner="entry?.banner?.[0]"
        />

        <MainHero
            key="home-hero"
            :background-color="entry?.homeHero?.[0]?.backgroundColor"
            variant="home"
            :images="entry?.homeHero?.[0]?.images"
            :video-mp4="entry?.homeHero?.[0]?.videoMp4"
            :video-web-m="entry?.homeHero?.[0]?.videoWebM"
            :position-video="entry?.homeHero?.[0]?.positionVideo"
            :poster="entry?.homeHero?.[0]?.videoPlaceholderImage"
            :pending="pending"
            :loading-state-shown="loadingStateShown"
        >
            <template #cta>
                <CallToAction parent-component="MainHero">
                    <template #description>
                        {{ entry?.homeHero?.[0].description }}
                    </template>

                    <!-- <template #cta>
                        {{ $t('book-your-stay') }}
                    </template> -->
                </CallToAction>
            </template>
        </MainHero>

        <TheBookingForm>
            <template #tagline>
                {{ offerTagLine }}
            </template>
        </TheBookingForm>

        <SegmentationTool
            v-if="entry?.segmentationTool?.length || pending"
            :pending="pending"
            :segments="entry?.segmentationTool"
        >
            <template #title>{{ entry?.segmentationTitle}}</template>
        </SegmentationTool>

        <PropertyList
            :pending="pending"
            :cities="response?.data?.value?.citiesEntries"
        >
            <template #title>{{ entry?.ourLocations?.[0]?.title }}</template>
            <template #intro>{{ entry?.ourLocations?.[0]?.description }}</template>
        </PropertyList>

        <MapBlock
            v-if="entry?.mapBlock?.[0] || pending"
            :markers="entry?.mapBlock?.[0]?.markers"
        >
            <template
                v-if="entry?.mapBlock?.[0]?.description"
                #description
            >
                {{ entry.mapBlock[0].description }}
            </template>
        </MapBlock>

        <StorytellingBlock
            :images="entry?.storytellingBlock?.[0]?.images"
            :background-color="entry?.storytellingBlock?.[0]?.backgroundColor"
            :pending="pending"
        >
            <template
                v-if="entry?.storytellingBlock?.[0]?.title"
                #title
            >
                {{ entry?.storytellingBlock?.[0].title }}
            </template>

            <template
                v-if="entry?.storytellingBlock?.[0]?.description"
                #description
            >
                {{ entry?.storytellingBlock?.[0].description }}
            </template>

            <template
                v-if="entry?.storytellingBlock?.[0]?.link"
                #link
            >
                <BaseArrowLink
                    :element="CraftLink"
                    :link="entry?.storytellingBlock?.[0].link"
                >
                    {{ entry?.storytellingBlock?.[0].link.text }}
                </BaseArrowLink>
            </template>
        </StorytellingBlock>

        <CarousselBlock
            v-if="entry?.carousselBlock?.[0]?.title || pending"
            :caroussel-items="entry?.carousselBlock?.[0]?.carousselItems"
            :background-color="entry?.carousselBlock?.[0]?.backgroundColor"
            :pending="pending"
        >
            <template
                v-if="entry?.carousselBlock?.[0]?.title"
                #title
            >
                {{ entry?.carousselBlock?.[0]?.title }}
            </template>

            <template
                v-if="entry?.carousselBlock?.[0]?.description"
                #description
            >
                {{ entry?.carousselBlock?.[0]?.description }}
            </template>
        </CarousselBlock>

        <NewsletterBlock
            v-if="entry?.newsletterBlock?.[0] || pending"
            :image="entry?.newsletterBlock?.[0].image?.[0]"
            :pending="pending"
            :background-color="entry?.newsletterBlock?.[0]?.backgroundColor"
            :list-id="entry?.newsletterBlock?.[0]?.listId ?? undefined"
        >
            <template
                v-if="entry?.newsletterBlock?.[0]?.title"
                #title
            >
                {{ entry.newsletterBlock[0].title }}
            </template>

            <template
                v-if="entry?.newsletterBlock?.[0]?.description"
                #description
            >
                {{ entry.newsletterBlock[0].description }}
            </template>
        </NewsletterBlock>
    </article>
</template>

<script setup>
import HomeQuery from '~/graphql/queries/home.graphql';

/** Get the page data */
const { variables } = useCraftGraphql();
const response = await useLazyAsyncQuery({
    query: HomeQuery,
    variables
});

const pending = response?.pending;
const { craftEntry: entry } = useCraftPage(response);

const offerTagLine = computed(() => toValue(response?.data)?.offers?.offerTagLine);

const CraftLink = resolveComponent('CraftLink');

// /** Show a loading state ONLY once per SPA session **/
const loadingStateShown = useState('loadAnimationShown', () => typeof loadingAnimationEnded === 'undefined' ? false : loadingAnimationEnded);

onMounted(() => {
    if (!loadingStateShown.value) {
        // Listen for the end of the transition and check the non-reactive `loadingAnimationEnded` in case we're on a slow machine.
        if (typeof loadingAnimationEnded === 'undefined' || loadingAnimationEnded === false) {
            if (document.querySelector('h1.main-hero__logo') === null) {
                setStateShown();
                return;
            }

            document.querySelector('h1.main-hero__logo').addEventListener('animationend', () => {
                setStateShown();
            });
        } else if (loadingAnimationEnded === true) {
            setStateShown();
        }
    }
});

// Inject this script into the head so it can fire before hydration even starts. Otherwise, on slow machines, the animation finished before we can even listen for it.
useHead({
    script: [
        {
            innerHTML: `
                var loadingAnimationEnded = false;
                window.addEventListener('animationend', (event) => {
                    if (event.animationName === 'topElementFade' && event.target.className === 'main-hero__logo') {
                        loadingAnimationEnded = true;
                    }
                });
            `
        }
    ]
});

function setStateShown() {
    loadingStateShown.value = true;
}
</script>

<style lang="less">
@media ( prefers-reduced-motion: no-preference ) {
    .home[data-show-loading-state='true'] {
        .animation-main-page();
    }
}

.home__offer-tag-line {
    .typography-text-sm();
    text-align: center;
    padding: var(--spacing-lg);
}
</style>
